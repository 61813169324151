import { render, staticRenderFns } from "./studentbzr.vue?vue&type=template&id=08c75ad0&scoped=true&"
import script from "./studentbzr.vue?vue&type=script&lang=js&"
export * from "./studentbzr.vue?vue&type=script&lang=js&"
import style0 from "./studentbzr.vue?vue&type=style&index=0&id=08c75ad0&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c75ad0",
  null
  
)

export default component.exports