<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <span style="margin: 0 0 10px 0;">选择班级：</span>
        <!-- <el-cascader ref="firstCascader_list" :options="ckdTreeData" :props="defaultProps" v-model="deptId"
					@change="changedept" :show-all-levels="false" clearable placeholder="请选择班级" filterable
					style="width: 150px;margin-right:10px" size="small">
        </el-cascader>-->

        <tchtree v-model="deptId" @change="changedept" @changeType="changeType" style="margin: 0 10px 10px 0;">
        </tchtree>


        <span v-if="$store.state.pmid == 215" style="margin: 0 10px 10px 10px;">届次：</span>
        <el-select v-if="$store.state.pmid == 215" clearable v-model="searchForm.school_year" size="small"
          placeholder="请选择" style="max-width:200px;margin: 0 10px 10px 0;"
          @change="() => { $forceUpdate(); getStudents() }">
          <el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
          </el-option>
        </el-select>

        <span style="margin: 0 10px 10px 0;">请输入关键字：</span>
        <el-input v-model="searchForm.keyword" size="small" placeholder="请输入关键字，多关键字使用空格隔开" clearable
          style="width: 200px;margin: 0 10px 10px 0;" />
        <span style="margin: 0 10px 10px 0;">状态：</span>
        <el-select v-model="searchForm.biye" size="small" style="width:100px;margin: 0 10px 10px 0;">
          <el-option :value="0" label="未毕业"></el-option>
          <el-option :value="1" label="已毕业"></el-option>
        </el-select>

        <el-button size="small" icon="el-icon-search" @click="() => { page.current_page = 1; getStudents() }"
          type="primary" style="margin: 0 10px 10px 0;">查询
        </el-button>

      </div>
      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
        <el-button type="primary" plain size="small" icon="el-icon-guide" @click="groupStudents"
          style="margin: 0 10px 0 0;">批量挂靠
        </el-button>

        <el-button type="primary" plain size="small" icon="el-icon-upload2" @click="importTeacherShow = true"
          style="margin: 0 10px 0 0;">导入学生
        </el-button>
        <el-button type="primary" plain size="small" icon="el-icon-plus" @click="addStudents"
          style="margin: 0 10px 0 0;">添加单个学生
        </el-button>

        <el-button type="primary" plain size="small" icon="el-icon-download" @click="exportPdfs"
          style="margin: 0 10px 0 0;">批量导出实习手册
        </el-button>
        <!-- 南高职和南京商业用 -->
        <el-button v-if="$store.state.pmid == 210 || $store.state.pmid == 214" type="primary" plain size="small"
          icon="el-icon-download" @click="exportXls_sxmc_njSchools" style="margin: 0 10px 0 0;">导出实习生名册
        </el-button>
        <el-button v-if="$store.state.pmid == 210 || $store.state.pmid == 214" type="primary" plain size="small"
          icon="el-icon-download" @click="exportXls_sxmc_njSchools(1)" style="margin: 0 10px 0 0;">导出实习生名册(全部学生)
        </el-button>
        <!-- 南京财经用 -->
        <el-button v-if="$store.state.pmid == 215" type="primary" plain size="small" icon="el-icon-download"
          @click="exportXls_sxmc('/api/export_sxmc_xls_njcj')" style="margin: 0 10px 0 0;">导出实习生名册
        </el-button>
        <!-- 南京卫生用 -->
        <el-button v-if="$store.state.pmid == 226" type="primary" plain size="small" icon="el-icon-download"
          @click="handleDownload('sxmc')" style="margin: 0 10px 0 0;">导出实习生名册
        </el-button>
        <!-- 其他学校用 -->
        <el-button
          v-if="$store.state.pmid != 210 && $store.state.pmid != 214 && $store.state.pmid != 215 && $store.state.pmid != 226"
          type="primary" plain size="small" icon="el-icon-download" @click="exportXls('/api/export_sxmc_xls')"
          style="margin: 0 10px 0 0;">导出实习生名册
        </el-button>

        <!-- <el-button type="primary" plain size="small" icon="el-icon-download" @click="exportXls('/api/export_bymc_xls')"
          style="margin: 0 10px 0 0;">导出毕业生名册</el-button> -->


        <el-button type="primary" plain size="small" icon="el-icon-download" @click="dialogExportFormVisible = true"
          style="margin: 0 10px 0 0;">导出实习审批表
        </el-button>

        <template v-if="njpmids.indexOf($store.state.pmid) >= 0 && $store.state.pmid != 214">
          <el-button type="primary" plain size="small" icon="el-icon-download"
            @click="exportXls('/api/down_jiuyemingce_saas')" style="margin: 0 10px 0 0;">导出毕业生名册(就业调查)
          </el-button>
          <el-button type="primary" plain size="small" icon="el-icon-download"
            @click="exportXls('/api/down_fenleibiao_saas')" style="margin: 0 10px 0 0;">毕业生分类表(就业调查)
          </el-button>
        </template>

        <el-button v-if="$store.state.pmid == 214" type="primary" plain size="small" icon="el-icon-download"
          @click="generateRecord" style="margin: 0 10px 0 0;">生成检查记录并导出
        </el-button>
        <el-button v-if="$store.state.pmid == 208 || $store.state.pmid == 210" type="primary" plain size="small"
          icon="el-icon-download" @click="dialogExportSxbg = true" style="margin: 0 10px 0 0;">导出实习报告
        </el-button>
        <el-button type="primary" plain size="small" icon="el-icon-download" @click="sxglTableExport"
          style="margin: 0 10px 0 0;">实习管理表导出
        </el-button>

      </div>
    </el-row>
    <div class="dividerBar"></div>

    <Table class="flex_1" style="width:100%;" :dictArr="[]" :needsp="needSp" :tableData="tableData" :page="page"
      :isMedicalSchool="isMedicalSchool" @handleModify="handleModify" @handleDel="handleDel"
      @handleSelectionChange="handleSelectionChange" from="dept" page_from="bzr">
    </Table>

    <el-dialog title="班级详情" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
      custom-class="cus_dialog">
      <el-form v-if="isShowModal" :model="formData" ref="adminUserInfo" label-width="120px" style>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级部门">
              <el-cascader ref="firstCascader" :options="treeData" :disabled="modalTitle == '修改'" :props="defaultProps"
                v-model="formData.parent_id" :show-all-levels="false" @change="handleCascaderChange" placeholder="请选择"
                filterable style="width: 80%"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级类型" required>
              <el-select v-model="formData.type" placeholder="请选择类型" style="width:80%">
                <el-option label="班级" value="bj"></el-option>
                <el-option label="实习小组" value="sxxz"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="班级名称" required>
              <el-input v-model.trim="formData.name" style="width: 80%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级代码" required>
              <el-input v-model.trim="formData.code" style="width: 80%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="实习开始时间">
              <el-date-picker :readonly="formData.type == 'bj' || formData.type == 'sxxz'" value-format="yyyy-MM-dd"
                v-model="formData.practice_start_date" style="width: 80%" type="date"
                placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实习结束时间">
              <el-date-picker :readonly="formData.type == 'bj' || formData.type == 'sxxz'" value-format="yyyy-MM-dd"
                v-model="formData.practice_end_date" style="width: 80%" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="排序号" prop="sort" required>
              <el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
                controls-position="right" />
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>

        <el-form-item label="班主任" required>
          <el-select v-model="formTeacher.bzr" filterable remote clearable reserve-keyword placeholder="请输入教师姓名"
            :remote-method="searchTeacher" style="width:80%">
            <el-option v-for="item in teacherList" :key="item.teacher_id"
              :label="item.username + (item.dept_name ? '[' + item.dept_name + ']' : '')"
              :value="item.teacher_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指导教师">
          <el-select v-model="formTeacher.zdls" multiple filterable remote clearable reserve-keyword
            placeholder="请输入教师姓名" :remote-method="searchTeacher" style="width:80%">
            <el-option v-for="item in teacherList" :key="item.teacher_id"
              :label="item.username + (item.dept_name ? '[' + item.dept_name + ']' : '')"
              :value="item.teacher_id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <TForm v-if="showTeacherInfo" :student_id="student_id" :dept_id="deptId" :needsp="needSp" page_from="bzr"></TForm>

    <stuTree v-if="DeptUserDialog"></stuTree>

    <el-dialog title="导入学生" :visible.sync="importTeacherShow" width="800px" :close-on-click-modal="false"
      :destroy-on-close="true" custom-class="cus_dialog">
      <div style="text-align:center">
        <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
          :on-success="handleXlsUpload" :show-file-list="false" name="image">
          <el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">选择文件</el-button>
        </el-upload>
        <el-button v-if="$store.state.pmid != 220" style="margin-left: 10px;" type="text" icon="el-icon-download">
          <a href="/xls/学生信息导入模板.xlsx" style="text-decoration: none;color:inherit">下载学生信息导入模板</a>
        </el-button>
         <el-button v-if="$store.state.pmid == 220" style="margin-left: 10px;" type="text" icon="el-icon-download">
          <a href="/xls/学生信息导入模板常州旅商.xlsx" style="text-decoration: none;color:inherit">下载学生信息导入模板1</a>
        </el-button>
      </div>
      <!-- <div style="text-align:center">
        <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
          :on-success="updPhone" :show-file-list="false" name="image">
          <el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">更新手机号</el-button>
        </el-upload>
      </div> -->
      <el-table v-if="failedImportData.length > 0" :data="failedImportData" style="width: 100%;padding-top: 20px;">
        <el-table-column label="查看" width="80" type="expand">
          <template slot-scope="props">
            <div v-for="(value,index) in props.row.errors" :key="index" style="color:#99a9bf;line-height: 26px;">{{
              value }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="filename" label="导入文件">
          <template v-slot="scope">
            <el-link :href="scope.row.file_url" type="primary">{{ scope.row.filename }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="cdate" label="导入时间"></el-table-column>
        <el-table-column prop="username" label="导入人"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="导出实习审批" class="cus_dialog" :visible.sync="dialogExportFormVisible" width="25%"
      custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">导出实习审批</span>
      </div>
      <el-form>
        <el-form-item label="导出格式">
          <el-radio v-model="export_format" label="word">Word</el-radio>
          <el-radio v-model="export_format" label="pdf">PDF</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExportFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportPdfs_sxsp(0)">导出</el-button>
        <el-button type="primary" plain @click="exportPdfs_sxsp(1)">导出全部学生</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导出实习报告" class="cus_dialog" :visible.sync="dialogExportSxbg" width="25%" custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">导出实习报告（默认导出所有）</span>
      </div>
      <el-form>
        <el-form-item label="选择届次">
          <el-select v-model="searchForm.jc" placeholder="请选择届次" clearable>
            <el-option v-for="j in jieci" :key="j" :value="j" :label="j"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择月份">
          <el-date-picker v-model="searchForm.report_month" value-format="yyyy-MM" type="month" placeholder="选择月">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExportSxbg = false">取 消</el-button>
        <el-button type="primary" @click="exportReport()">导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import User from "./components/User";
import Table from "./components/StudentTable";
import TForm from "./components/StudentForm";

import stuTree from "./components/stuTree.vue";
import tchtree from "../com/tchTree.vue";

import {ExportBriefDataDocx} from './../../utils/exportBriefDataDocx'
import {exportWord, exportWordBlob} from "@/utils/exportword";
import moment from "moment";
import {njxx_sxmc_blob, njxx_sxmc_export} from "../../../public/js/Export2Excel";
import {downloadZip} from "@/utils/downloadZip";

export default {
  name: "dept",
  components: {
    User,
    Table,

    TForm,
    stuTree,
    tchtree
  },
  data() {
    return {
      visible: false,
      isShowModal: false,
      isShowModalAuth: false,
      importTeacherShow: false,
      modalTitle: "添加",
      DeptUserDialog: false,
      activeRoleID: "",
      biye: 0,
      formData: {},
      formTeacher: {
        bzr: "",
        zdls: []
      },
      ckdTreeData: [],

      defaultProps: {
        label: "name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true
      },

      activeName: "CN",
      tableData: [],

      teacherList: [],
      editUser: {},
      deptId: 0,
      deptName: "",
      editDeptRole: {},
      deptRoleList: [],
      isShowDeptRole: false,

      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {
        biye: 0,
        ktype: "stu_name",
        keyword: ""
      },
      multipleSelection: [],
      treeData: [],
      firstCascaderProps: {
        label: "name",
        children: "children"
      },
      DataAuth: [],
      showTeacherInfo: false,
      student_id: 0,
      needSp: false,
      qxArr: [],
      dictArr: [],
      docxData: {},
      dialogExportFormVisible: false,
      export_format: 'word',
      schoolYears: [],
      className: '',
      njpmids: [209, 210, 214, 215, 223, 226],
      failedImportData: [],
      deptType: 'xx',
      isMedicalSchool:false,
      sxbgTimer: null,
      dialogExportSxbg: false,
    };
  },

  mounted() {
    if (this.$store.state.pmid == 226) {
      this.isMedicalSchool = true
    }
    this.getTree();
    this.getDeptRoles();
    this.getFailedImport()
    this.getSchoolYear().then(_ => {

      this.getDic().then(r => {
        //学生个人信息编辑后是否需要审批
        this.$http.post("/api/sch_config_one", {ktype: "student_shenpi"}).then(res => {
          if (res.data) {
            if (parseInt(res.data.kvalue) == 1) {
              this.needSp = true
            }
          }
          this.getStudents();
        })
      })

    });
  },
  watch: {
    deptId(val) {
      if (!val) {
        this.deptType = 'xx'
      }
    },
  },
  computed: {
    jieci() {
      let m = moment().month() + 1
      let year = moment().year()
      if (m < 9) {
        year--
      }
      let years = []
      for (let i = 0; i < 5; i++) {
        years.push(year-i)
      }
      //this.searchForm.jc = years[0]
      return years
    },
  },
  methods: {
    changedept(e) {
      // this.deptName = this.$refs["firstCascader_list"].getCheckedNodes()[0].label;

      this.getStudents();
      this.getClassName()

    },
    handleCommand(e) {
      // <el-dropdown-item command="groupStudents" icon="el-icon-guide">学生分组/挂靠
      // 						</el-dropdown-item>
      // 						<el-dropdown-item command="importStudent" divided icon="el-icon-upload2">导入学生</el-dropdown-item>
      // 						<el-dropdown-item command="downStuTemp" divided icon="el-icon-download">下载学生模板</el-dropdown-item>
      // 						<el-dropdown-item command="addStudents" divided icon="el-icon-plus">添加单个学生</el-dropdown-item>

      if (e == "groupStudents") {
        this.groupStudents();
      } else if (e == "addStudents") {
        this.addStudents();
      } else if (e == "importStudent") {
        $("#btnimport").click();
      } else if (e == "downStuTemp") {
        window.open("/xls/学生信息导入模板.xlsx", "_blank");
      }
    },
    handleXlsUpload(e) {
      this.$http.post("/api/import_student_xls", {url: e.src, filename: e.fileName}).then(res => {
        this.$message.success("导入成功");
        this.getStudents();
        this.importTeacherShow = false;
      }).catch(err => {
        this.getFailedImport()
      });
    },
    updPhone(e){
      this.$http.post("/api/import_student_xls_upd_phone", { url: e.src, filename: e.fileName }).then(res => {
        this.$message.success("导入成功");
        this.getStudents();
        this.importTeacherShow = false;
      }).catch(err => {
        this.getFailedImport()
      });
    },
    getSchoolYear() {
      return new Promise((resolve, reject) => {
        if (this.$store.state.pmid == 215) {
          let m = moment().format('M')
          let nowYear = moment().year()
          let schoolYears = []
          if (m >= 5) {
            nowYear++
          }
          for (let i = nowYear; i >= 2019; i--) {
            let s = (i - 1) + "-" + i
            let s_label = s + '学年(' + (i - 5) + '届)'
            schoolYears.push({title: s_label, value: s})
          }
          this.schoolYears = schoolYears
          this.searchForm.school_year = schoolYears[0].value
        }
        resolve()
      })
    },
    getTree1() {
      this.$http.post("/api/get_tch_cls_data", {}).then(res => {
        // res.data.list.map(e => {
        // 	e.name = e.upname + "/" + e.name
        // })
        this.treeData = res.data.list;
        this.ckdTreeData = this.treeData;
      });
    },
    getTree() {
      this.$http
          .post("/api/get_tch_cls_data", {parent_id: 0, biye: this.biye})
          .then(res => {
            this.treeData = res.data.list;
            this.ckdTreeData = this.treeData;
            if (this.treeData.length == 1) {
              this.deptId = this.treeData[0].id;
            }
          });
    },

    setBiyeType() {
      if (this.biye === 0) {
        this.biye = 1;
      } else if (this.biye === 1) {
        this.biye = "";
      } else if (this.biye === "") {
        this.biye = 0;
      }
      this.getTree();
    },
    searchTeacher(e) {
      let params = {
        page: 1,
        pagesize: 20,
        ktype: "username",
        keyword: e
      };
      // sys_dept_user_related_list
      this.$http.post("/api/t_sch_teachers_list", params).then(res => {
        for (let item of res.data.data) {
          if (item.dept_roles && item.dept_roles.length > 0) {
            item.dept_name = item.dept_roles
                .map(e => {
                  return e.name;
                })
                .join(",");
          }
          item.teacher_id = item.id;
        }
        this.teacherList = res.data.data;
      });
    },
    getDic() {
      return new Promise(resolve => {
        this.$http
            .post("/api/sys_param_list", {param_type: "'SHIXI_STATUS'"})
            .then(res => {
              let qxArr = [];
              res.data.map((item, index, arr) => {
                if (item.param_type == "SHIXI_STATUS") {
                  qxArr.push(item);
                }
              });
              this.qxArr = qxArr;
              resolve();
            });
      });
    },

    getStudents() {
      let params = {
        page: this.page.current_page,
        pagesize: this.page.per_page,
        ktype: this.searchForm.ktype,
        keyword: this.searchForm.keyword,
        // dept_id: this.searchForm.keyword ? '' : this.deptId,
        // class_id: this.searchForm.keyword ? '' : this.deptId,
        office_id: this.deptId,
        biye: this.searchForm.biye,
        school_year: this.searchForm.school_year,
      };

      // sys_dept_user_related_list
      this.$http.post("/api/t_sch_students_list", params).then(res => {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map(a => {
            this.qxArr.map(d => {
              // console.log(a.sxqx , d.param_value)
              if (a.sxqx == d.param_value) {
                a.sxqxLabel = d.param_desc;

                //如果是实习中，检查是否在开始时间
                if (a.sxqx == 1 && a.start_time && a.end_time) {
                  if (d.param_value == a.sxqx) {
                    a.sxqxLabel = d.param_desc;
                    //如果是实习中，检查是否在开始时间
                    if (a.sxqx == 1 && a.start_time && a.end_time) {
                      let ntime = parseInt(
                          this.$moment(new Date())
                              .add("year", 0)
                              .format("YYYY-MM-DD")
                              .replace(/-/g, "")
                      );
                      let start_time = parseInt(a.start_time.replace(/-/g, ""));
                      let end_time = parseInt(a.end_time.replace(/-/g, ""));
                      // console.log(888,a.stu_name,a.sxqx,ntime,a.start_time)
                      if (ntime >= start_time && ntime <= end_time) {
                        // console.log(111, a.stu_name);
                        // a.sxqxLabel = '实习中'
                      } else if (ntime > end_time) {
                        // console.log(222, a.stu_name);
                        a.sxqxLabel = "实习结束";
                      } else if (ntime < start_time) {
                        // console.log(333, a.stu_name);
                        a.sxqxLabel = "待实习";
                      }
                    }
                  }
                }
              }
            });
          });
        }
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    addStudents() {
      // let ckdClass = this.$refs['firstCascader'].getCheckedNodes()

      // if (!ckdClass[0]) {
      // 	this.$message.error("请先选择班级")
      // 	return
      // }
      // ckdClass = ckdClass[0].data
      // if (ckdClass.type != 'bj') {
      // 	this.$message.error("只能在班级中添加学生")
      // 	return
      // }

      this.student_id = 0;
      this.showTeacherInfo = true;
    },

    getDeptRoles() {
      this.$http.post("/api/sys_dept_role_list").then(res => {
        this.deptRoleList = res.data.data;
      });
    },

    handleNodeClick(data) {
      // console.log(data);
      this.deptId = data.id;

      this.deptInfo = data;
      this.ckdTreeData = [data];
      this.getStudents();
    },
    getClassName() {
      this.$http.post("/api/sys_office_info", {id: this.deptId}).then(res => {
        if (res && res.data && res.data.name) {
          this.className = res.data.name
        } else {
          this.className = ''
        }
      });
    },
    handleSelectionChange(val) {
      let _this = this;
      _this.multipleSelection = val;
    },
    formAdd() {
      this.formData = {
        parent_id: 0,
        sort: 1,
        type: "",
        name: "",
        roles: []
      };
      this.formData.type = "";
      this.modalTitle = "新增部门";
      this.isShowModal = true;
    },
    exportPdfs() {
      let arr = [];
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        this.$message.error("请先选择学生");
      } else {
        for (let stu of this.multipleSelection) {
          let obj = {
            id: stu.id,
            name: stu.stu_name,
            class_name: stu.class_name
          };
          arr.push(obj);
        }
        let params = {
          stuArr: arr,
          pmid: window.pmid,
          latest: 1
        };

        if (arr.length > 0) {
          this.$http
              .post(this.$pdfUrl + "/exportStuPdf", params)
              .then(res => {
                if (arr.length > 10) {
                  let loadingInstance = this.$loading({
                    lock: true,
                    text: "正在导出请稍等...",
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                  })
                  this.timer = setInterval(async () => {
                    let result = await this.$http.post(this.$pdfUrl + "/getFileUrl", {
                      rkey: res.data.id,
                      noloading: true
                    })
                    if (result.data.url) {
                      clearInterval(this.timer)
                      loadingInstance.close()
                      window.open(result.data.url, "_blank")
                    }
                  }, 3000)
                } else {
                  this.$message({
                    type: "success",
                    message: "生成成功!"
                  });
                  window.open(res.data.url, "_blank");
                }
              });
        }
      }
    },
    exportPdfs_sxsp1() {
      if (!this.deptId) {
        this.$message.warning("请先选择班级~");
        return;
      }
      let params = {
        "class_id ": this.deptId,
        stuArr: [
          {
            id: this.deptId,
            name: this.deptName,
            class_name: this.deptName
          }
        ],
        pmid: window.pmid,
        latest: 1
      };

      this.$http.post(this.$pdfUrl + "/exportTchPdf_sxsp", params).then(res => {
        this.$message({
          type: "success",
          message: "生成成功!"
        });
        window.open(res.data.url, "_blank");
      });
    },

    handleDoxData() {
      let _this = this
      return new Promise(resolve => {
        this.$http.post('/api/initSxspPdf', {
          class_id: parseInt(this.deptId),
          pmid: parseInt(window.pmid)
        }).then(res => {
          if (res.data) {
            let rData = res.data
            rData.stutotal = 0
            rData.stusx = 0

            if (rData.list && rData.list.length > 0) {
              rData.stutotal = rData.list.length
              rData.list.map(a => {
                a.department = a.department || ''
                a.firm_content_name = a.firm_content_name || ''
                a.firm_content_tel = a.firm_content_tel || ''
                a.firm_name = a.firm_name || ''
                a.info_name = a.info_name || ''
                a.job = a.job || ''
                a.salary = a.salary || ''
                a.remarks = a.remarks || ''
                if (a.firm_name) {
                  rData.stusx += 1
                }
              })
            }
            this.docxData = rData
            console.log(370, rData)
            resolve()

          } else {
            resolve({})
          }
          this.$nextTick(() => {
            setTimeout(() => {
              _this.pdfok = true
            }, 500)
          })
        })
      })
    },
    exportPdfs_sxsp(is_all) {
      // if (!this.deptId) {
      //   this.$message.warning("请先选择班级~");
      //   return;
      // } else {
      /*this.handleDoxData().then(rr => {
        // ExportBriefDataDocx('模板文件', 要传入模板的数据, '文档名字.docx')
        let docxName = `${this.docxData.bj_name}-实习审批表`
        ExportBriefDataDocx('/exportTemp/实习审批表.docx', this.docxData, `${docxName}.docx`) // text.docx放在了根目录下的public文件夹下
      })*/
      // }
      this.dialogExportFormVisible = false
      if (this.export_format === 'pdf') {
        if (!this.deptId) {
          this.$message.warning("请先选择班级~");
          return;
        }
        this.$http.post(this.$pdfUrl + '/exportTchPdf_sxsp_new', {
          pmid: window.pmid,
          latest: 1,
          is_all: is_all == 1 ? is_all : 0,
          stuArr: [
            {
              id: this.deptId,
              name: this.className,
              class_id: this.deptId,
            }
          ]
        }).then(res => {
          this.classArr = []
          this.$message({
            type: 'success',
            message: '生成成功!'
          });
          window.open(res.data.url, "_blank")
        })
        return
      }
      this.$http.post('/api/t_sch_stu_enter_export', {
        class_ids: this.deptId, is_all: is_all == 1 ? is_all : 0
      }).then(res => {
        let data = res.data
        if (this.deptType != 'bj') {
          this.handleExportSxsqData(data)
        } else {
          let temp = "./docs/"
          if (window.pmid == 214) {
            temp += "毕业实习审批表模板njsyxx.docx"
          } else if (this.njpmids.indexOf(window.pmid) >= 0) {
            temp += "毕业实习审批表模板.docx"
          } else {
            temp += "毕业实习审批表模板2.docx"
          }
          let date = new Date()
          data.year = date.getFullYear()
          data.month = date.getMonth() + 1
          data.day = date.getDate()
          data.classes.map(d => {
            d.teacher_name = d.teacher_name ?? ""
            d.phone = d.phone ?? ""
            d.mentor_name = d.mentor_name ?? ""
            d.mentor_tel = d.mentor_tel ?? ""
            d.start_time = d.start_time ? d.start_time.replaceAll('-', '/') : ""
            d.end_time = d.end_time ? d.end_time.replaceAll('-', '/') : ""
            d.major_name = d.major_name || d.yx_name
            if (window.pmid == 226) {
              if (d.class_name && (d.class_name.indexOf('护理') > -1 || d.class_name.indexOf('助产') > -1)) {
                d.mentor_name = '尹健'
                d.mentor_tel = '15850597280'
              }
              if (d.class_name && (d.class_name.indexOf('护理') < 0 && d.class_name.indexOf('助产') < 0)) {
                d.mentor_name = '郑秀明'
                d.mentor_tel = '13951824274'
              }
            }
          })
          data.enters.map(e => {
            e.department = e.department ?? ""
            e.job = e.job ?? ""
            e.remarks = e.remarks ?? ""
            e.firm_content_name = e.firm_content_name ?? ""
            e.firm_content_tel = e.firm_content_tel ?? ""
            e.salary = e.salary ?? ""
            e.xinzi = e.xinzi ?? ""
            e.enter_info = e.enter_info ?? ""
            if (window.pmid == 226) {
              e.enter_info = '公立'
              e.salary = '2000元以下'
              if (e.firm_name && (e.firm_name.indexOf('明基') > -1 || e.firm_name.indexOf('同仁') > -1 || e.firm_name.indexOf('泰康') > -1 || e.firm_name.indexOf('紫金') > -1)) {
                e.enter_info = '民营'
              }
              if (e.class_name && (e.class_name.indexOf('护理') > -1 || e.class_name.indexOf('助产') > -1)) {
                e.department = '护理部'
                e.job = '护理'
                e.yx_wd = '未定'
              }
              if (window.pmid == 226 && e.class_name && e.class_name.indexOf('康复') > -1) {
                e.department = '康复科'
                e.job = '康复'
              }
              if (window.pmid == 226 && e.class_name && e.class_name.indexOf('老年') > -1) {
                e.department = '养老护理部'
                e.job = '护理员'
                e.enter_info = '民营'
              }
              if (window.pmid == 226 && e.class_name && (e.class_name.indexOf('医电') > -1 || e.class_name.indexOf('影像') > -1 || e.class_name.indexOf('放射') > -1)) {
                e.department = '影像科'
                e.job = '技士'
              }
            }
          })
          let docxName = `${data.classes[0].class_name}-毕业实习审批表.docx`
          exportWord(temp, data, docxName)
        }
      })
    },
    exportXls(url) {
      this.$http.post(url, {class_id: this.deptId,dept_type: this.deptType}).then(res => {
        let url = res.data.url
        url = url.substring(0, url.lastIndexOf('/') + 1) + encodeURIComponent(url.substring(url.lastIndexOf('/') + 1))
        window.open(url, "_blank");
      });
    },
    exportXls_sxmc(url) {
      if (this.deptId) {
        this.exportXls(url)
      } else {
        this.$confirm('未选择班级，是否按照当前所选学年导出?', '提示', {
          confirmButtonText: '确定导出',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url, {school_year: this.searchForm.school_year,}).then(res => {
            window.open(res.data.url, "_blank");
          });
        }).catch(() => {

        });
      }
    },
    exportXls_sxmc_njSchools(is_all) {
      this.$http.post('/api/stu_enter_mc_export2', {
        class_ids: this.deptId,
        is_all: is_all == 1 ? is_all : 0,
        dept_type: this.deptType,
      }).then(res => {
        let data = res.data
        let filename = ""
        let items = []
        if (data && data.length > 1) {
          if (this.deptType == 'yx' || this.deptType == 'jc') {
            filename = data && data.length > 0 ? data[0]['xibu_name'] + "-" : ""
          }
          for (let item of data) {
            let obj = {}
            if (this.deptType == 'xx') {
              obj.filename = item.xibu_name
              obj.children = []
              for (let classItem of item.classes) {
                obj.children.push(this.handleExportSxmcData(classItem, true))
              }
            } else {
              obj = this.handleExportSxmcData(item,true)
            }
            items.push(obj)
          }
          downloadZip(items, `${filename}毕业生实习名册`)
        } else {
          let info = data[0];
          let exportData = this.handleExportSxmcData(info)
          filename = info.class_name ? info.class_name + '-' : ''
          if (window.pmid == 210 || window.pmid == 214) {
            filename = `${filename}毕业生顶岗实习名册`
            njxx_sxmc_export(exportData, filename)
          } else {
            exportWord('./docs/毕业班实习生名册模板.docx', exportData, `${filename}毕业班实习生名册.docx`)
          }
        }
        //exportWord('./docs/毕业班实习生名册模板.docx', data, `${this.className ? this.className : '毕业班'}实习生名册`)
      })
    },
    getCurrentAgeByBirthDate(strBirthday) {
      /**
       * 通过出生日期获取当前年龄
       * @param strBirthday：指的是出生日期，格式为"1990-01-01",字符串类型
       */
          // 将出生日期的字符串通过"-"分割成数组
      const strBirthdayArr = strBirthday.split("-")
      // 拿到出生日期的年
      const birthYear = strBirthdayArr[0]
      // 拿到出生日期的月
      const birthMonth = strBirthdayArr[1]
      // 拿到出生日期的日
      const birthDay = strBirthdayArr[2]
      // 创建一个时间对象
      const d = new Date()
      // 拿到当前时间的年
      const nowYear = d.getFullYear()
      // 拿到当前时间的月
      const nowMonth = d.getMonth() + 1
      // 拿到当前时间的日
      const nowDay = d.getDate()
      // 如果出生日期的年等于当前时间的年
      if (nowYear === birthYear) return 0 // 返回周岁年龄 0,并终止函数执行
      // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
      const ageDiff = nowYear - birthYear; // 年之差
      // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
      if (ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
      // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
      if (nowMonth !== birthMonth) {
        // 拿到出生日期的日与当前时间的月之差
        const monthDiff = nowMonth - birthMonth; // 月之差
        // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
        return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
      }
      // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
      const dayDiff = nowDay - birthDay;
      // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
      return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
    },

    handleAdd(row) {
      this.formData = {
        parent_id: row.id,
        sort: 1,
        type: "",
        name: "",
        roles: []
      };
      this.modalTitle = "新增部门";
      this.isShowModal = true;
    },
    handleModify(row) {
      let formData = JSON.parse(JSON.stringify(row));
      this.formData = formData;

      let formTeacher = {
        bzr: "",
        zdls: []
      };

      if (formData.teachers) {
        for (let item of formData.teachers) {
          if (item.user_type == "bzr") {
            formTeacher.bzr = item.teacher_id;
          } else if (item.user_type == "zd") {
            formTeacher.zdls.push(item.teacher_id);
          }
        }
        this.teacherList = formData.teachers;
      }
      this.formTeacher = formTeacher;
      // console.log(this.teacherList);
      this.isShowModal = true;
    },
    resetRoles() {
      let extItem = [];
      //删除移除的角色
      for (let er of this.DataAuth) {
        let ext = false;
        for (let r of this.formData.roles) {
          if (er.role_id == r) {
            ext = true;
            break;
          }
        }
        if (ext) {
          extItem.push(er);
        }
      }
      this.DataAuth = extItem;
      //添加新增的角色..
      for (let r of this.formData.roles) {
        let ext = false;
        for (let er of this.DataAuth) {
          if (r == er.role_id) {
            ext = true;
            break;
          }
        }
        if (!ext) {
          let role_name = "";
          for (let item of this.deptRoleList) {
            if (item.id == r) {
              role_name = item.role_name;
            }
          }
          this.DataAuth.push({
            role_id: r,
            role_name: role_name,
            dept_data: []
          });
        }
      }
    },
    handleDel(row) {
      this.$http
          .post("/api/t_sch_students_delete", {
            id: String(row.id)
          })
          .then(res => {
            this.$message.success("删除成功");
            this.getStudents();
          });
    },
    handleCascaderChange(e) {
      // console.log(1, e);
    },

    submitForm(formName) {
      let params = {
        ...this.formData
      };
      if (!params.parent_id) {
        params.parent_id = 0;
      }
      if (!this.formData.name) {
        this.$message.error("请输入班级/小组名称");
        return;
      }
      if (!this.formData.type) {
        this.$message.error("请选择是班级还是小组");
        return;
      }
      if (!this.formData.code) {
        this.$message.error("请输入班级代码");
        return;
      }
      if (!this.formTeacher.bzr) {
        this.$message.error("请选择班主任");
        return;
      }

      this.$http.post("/api/sys_office_edit", params).then(res => {
        this.$message.success("保存成功");

        this.isShowModal = false;
        if (res.data.id) {
          this.formData.id = res.data.id;
        }

        if (this.formTeacher.bzr) {
          //直选一个的写法
          this.$http
              .post("/api/sys_teacher_class_related_edit", {
                class_id: this.formData.id,
                teacher_ids: this.formTeacher.bzr + "",
                user_type: "bzr"
              })
              .then(res => {
              });
        }
        if (this.formTeacher.zdls && this.formTeacher.zdls.length > 0) {
          this.$http
              .post("/api/sys_teacher_class_related_edit", {
                class_id: this.formData.id,
                teacher_ids: this.formTeacher.zdls.join(","),
                user_type: "zd"
              })
              .then(res => {
              });
        }
        setTimeout(() => {
          this.getTree();
        }, 500);
      });
    },

    getUnique2(arr) {
      const map = {};
      // 1、把数组元素作为对象的键存起来（这样就算有重复的元素，也会相互替换掉）
      arr.forEach(item => (map[JSON.stringify(item)] = item));

      // 2、再把对象的值抽成一个数组返回即为不重复的集合
      return Object.keys(map).map(key => map[key]);
    },

    showDeptRole(row) {
      this.deptId = row.id;
      this.isShowDeptRole = true;
    },

    showRoleAuth() {
      if (this.DataAuth.length == 0) {
        this.$message.error("请选择职位");
      } else {
        this.isShowModalAuth = true;
        setTimeout(() => {
          if (this.DataAuth.length > 0) {
            this.$refs["dataauthtree"].setCheckedKeys(
                this.DataAuth[0].dept_data
            );
          }
        }, 300);
      }
    },
    setOk() {
      this.isShowModalAuth = false;
    },
    handleRoleChange() {
      for (let tab of this.DataAuth) {
        if (tab.role_id == this.activeRoleID) {
          this.$refs["dataauthtree"].setCheckedKeys(tab.dept_data);
          break;
        }
      }
    },
    checkAuthData(a, b, c) {
      for (let tab of this.DataAuth) {
        if (tab.role_id == this.activeRoleID) {
          tab.dept_data = this.$refs["dataauthtree"].getCheckedKeys();
          break;
        }
      }
    },

    groupStudents() {
      if (this.multipleSelection.length > 0) {
        this.DeptUserDialog = true;
      } else {
        this.$message.error("请先选中学生");
      }
    },
    saveGroupUser(class_id) {
      console.log("分到这个小组：" + class_id);
      this.$http
          .post("/api/get_sxgroup_stus", {pagesize: 2000, class_id: class_id})
          .then(res => {
            let stuDic = {};
            for (let stu of res.data) {
              stuDic[stu.id] = 1;
            }
            let arr = [];
            // console.log(this.multipleSelection);
            // console.log(stuDic);
            for (let stu of this.multipleSelection) {
              if (!stuDic[stu.id]) {
                arr.push(stu.id);
              }
            }

            if (arr.length > 0) {
              this.$http
                  .post("/api/sys_stu_class_related_edit", {
                    ids: arr.join(","),
                    class_id: class_id
                  })
                  .then(rdata => {
                    this.getStudents();
                    this.$message.success("分组成功！");
                  });
            } else {
              this.$message.error("挂靠失败，已经挂靠该分组");
            }
          });
    },
    generateRecord() {
      let ids = this.multipleSelection.map(s => s.id)
      if (!ids.length) {
        this.$message.error("请先选择学生");
        return
      }
      this.$http.post('/api/generate_check_record', {ids}).then(res => {
        this.exportPdfs()
      })
    },
    getFailedImport() {
      this.$http.post('/api/failed_import_list', {module:'student_import'}).then(res => {
        res.data.map(r => {
          r.errors = JSON.parse(r.errors)
        })
        this.failedImportData = res.data
      })
    },
    changeType(val) {
      this.deptType = val
    },
    handleExportSxmcData(item, isBlob = false) {
      item.year = new Date().getFullYear()
      item.major_name = item.major_name || item.xibu_name
      let num = 1
      item.students.map(s => {
        s.age = 0
        if (s.birthday) {
          s.age = this.getCurrentAgeByBirthDate(s.birthday)
        }
        s.id = num
        s.zzmm = s.zzmm == '团员' ? "是" : "否"
        s.gender = s.gender == 1 ? "男" : '女'
        s.phone = s.phone ?? ""
        s.remarks = s.remarks ?? ''
        s.jtzz = s.jtzz ?? ''
        num++
      })
      if (window.pmid == 210 || window.pmid == 214) {
        let excelData = [
          [`${item.year}届毕业生顶岗实习名册`],
          ['填表说明：1.学校名称、实习单位名称请完整填写。\n' +
          '2.专业名称请按照专业目录规范填写。\n' +
          '3.实习起止日期请按实际填写。\n' +
          (window.pmid == 214 ? '4.实习渠道与录取意向，请在单元格的下拉列表中选择符合项。' : '4.录取意向：留用、未定、不留用')],
          [`学校：${item.school_name}                  专业：${item.major_name}                班级名称：${item.class_name}                 班主任：${item.teacher_name}               联系电话：${item.phone}`],
          ['序号', '姓名', '性别', '年龄', '实习单位', '实习部门', '实习岗位', '实习时间', '', '实习渠道','录取意向', '联系方式'],
          ['', '', '', '', '', '', '', '起', '止', '', '','']
        ]
        item.students.forEach(stu => {
          excelData.push([
            stu.id,
            stu.stu_name,
            stu.gender,
            stu.age,
            stu.firm_name,
            stu.department,
            stu.job,
            stu.start_time,
            stu.end_time,
            stu.anpai_type == 0 ? '学校推荐' : stu.anpai_type == 1 ? '学生自联' : '其他渠道',
            stu.start_time ? '留用' : '未定',
            stu.phone,
          ])
        })
        if (isBlob) {
          return {
            blob: njxx_sxmc_blob(excelData),
            filename: `${item.class_name ? item.class_name + '-' : ''}毕业生顶岗实习名册.xlsx`
          }
        }
        return excelData
      }

      if (isBlob) {
        return {
          blob: exportWordBlob('./docs/毕业班实习生名册模板.docx', item),
          filename: `${item.class_name ? item.class_name + '-' : ''}毕业班实习生名册.docx`
        }
      }
      return item
    },
    handleExportSxsqData(data) {
      let zipName = ""
      let templateName = "毕业实习审批表模板2.docx"
      if (window.pmid == 210) {
        templateName = "毕业实习审批表模板.docx"
      } else if (window.pmid == 214) {
        templateName = "毕业实习审批表模板njsyxx.docx"
      }
      let date = new Date()
      let wordBlob = []
      let wordData = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        school_name: data.school_name,
      }
      data.classes.map(d => {
        d.teacher_name = d.teacher_name ?? ""
        d.phone = d.phone ?? ""
        d.mentor_name = d.mentor_name ?? ""
        d.mentor_tel = d.mentor_tel ?? ""
        d.start_time = d.start_time ? d.start_time.replaceAll('-', '/') : ""
        d.end_time = d.end_time ? d.end_time.replaceAll('-', '/') : ""
        d.major_name = d.major_name || d.yx_name
        if (window.pmid == 226) {
          if (d.class_name && (d.class_name.indexOf('护理') > -1 || d.class_name.indexOf('助产') > -1)) {
            d.mentor_name = '尹健'
            d.mentor_tel = '15850597280'
          }
          if (d.class_name && (d.class_name.indexOf('护理') < 0 && d.class_name.indexOf('助产') < 0)) {
            d.mentor_name = '郑秀明'
            d.mentor_tel = '13951824274'
          }
        }
      })
      let enters = data.enters
      let entersObj = {}
      enters.forEach(e => {
        e.department = e.department ?? ""
        e.job = e.job ?? ""
        e.remarks = e.remarks ?? ""
        e.firm_content_name = e.firm_content_name ?? ""
        e.firm_content_tel = e.firm_content_tel ?? ""
        e.salary = e.salary ?? ""
        e.enter_info = e.enter_info ?? ""
        if (window.pmid == 226) {
          e.enter_info = '公立'
          e.salary = '2000元以下'
          if (e.firm_name && (e.firm_name.indexOf('明基') > -1 || e.firm_name.indexOf('同仁') > -1 || e.firm_name.indexOf('泰康') > -1 || e.firm_name.indexOf('紫金') > -1)) {
            e.enter_info = '民营'
          }
          if (e.class_name && (e.class_name.indexOf('护理') > -1 || e.class_name.indexOf('助产') > -1)) {
            e.department = '护理部'
            e.job = '护理'
            e.yx_wd = '未定'
          }
          if (window.pmid == 226 && e.class_name && e.class_name.indexOf('康复') > -1) {
            e.department = '康复科'
            e.job = '康复'
          }
          if (window.pmid == 226 && e.class_name && e.class_name.indexOf('老年') > -1) {
            e.department = '养老护理部'
            e.job = '护理员'
            e.enter_info = '民营'
          }
          if (window.pmid == 226 && e.class_name && (e.class_name.indexOf('医电') > -1 || e.class_name.indexOf('影像') > -1 || e.class_name.indexOf('放射') > -1)) {
            e.department = '影像科'
            e.job = '技士'
          }
        }

        if (!entersObj[e.class_id]) {
          entersObj[e.class_id] = []
        }
        entersObj[e.class_id].push(e)
      })

      if (this.deptType == 'xx') {
        let xibuObj = {}
        data.classes.forEach(c => {
          if (!xibuObj[c.yx_name]) {
            xibuObj[c.yx_name] = []
          }
          xibuObj[c.yx_name].push(c)
        })
        let xibus = Object.keys(xibuObj)
        for (let xibu of xibus) {
          let blobObj = {
            filename: xibu,
            children: [],
          }
          let classes = xibuObj[xibu] // 系部下的班级
          for (let classInfo of classes) {
            let enters = entersObj[classInfo.class_id]
            let tmp = {...wordData,classes: [classInfo],enters: enters}
            let blob = exportWordBlob(`./docs/${templateName}`,tmp)
            blobObj.children.push({filename: `${classInfo.class_name}-毕业实习审批表.docx`,blob})
          }
          wordBlob.push(blobObj)
        }
      } else {
        for (let classInfo of data.classes) {
          let enters = entersObj[classInfo.class_id]
          let tmp = {...wordData,classes: [classInfo],enters: enters}
          let blob = exportWordBlob(`./docs/${templateName}`,tmp)
          wordBlob.push({filename: `${classInfo.class_name}-毕业实习审批表.docx`,blob})
        }
        zipName = `${data.classes[0].yx_name}-`
      }
      downloadZip(wordBlob,`${zipName}毕业实习审批表`)
    },

    handleDownload(type) {
      if (!this.deptId) {
        this.deptType = 'xx'
      }
      if (type == 'sxmc') {
        this.$http.post('/api/stu_enter_mc_export2', {
          class_ids: this.deptId,
          dept_type: this.deptType,
        }).then(res => {
          let data = res.data
          let filename = ""
          let items = []
          if (data && data.length > 1) {
            if (this.deptType == 'yx' || this.deptType == 'jc') {
              filename = data && data.length > 0 ? data[0]['xibu_name'] + "-" : ""
            }
            for (let item of data) {
              let obj = {}
              if (this.deptType == 'xx') {
                obj.filename = item.xibu_name
                obj.children = []
                for (let classItem of item.classes) {
                  obj.children.push(this.handleExportSxmcData_new(classItem, true))
                }
              } else {
                obj = this.handleExportSxmcData_new(item, true)
              }
              items.push(obj)
            }
            downloadZip(items, `${filename}毕业生实习名册`)
          } else {
            let info = data[0];
            let exportData = this.handleExportSxmcData_new(info)
            filename = info.class_name ? info.class_name + '-' : ''
            if (window.pmid == 210 || window.pmid == 214) {
              filename = `${filename}毕业生顶岗实习名册.xlsx`
              njxx_sxmc_export(exportData, filename)
            } else {
              exportWord('./docs/毕业班实习生名册模板.docx', exportData, `${filename}毕业班实习生名册.docx`)
            }
          }
        })
      }
    },
    handleExportSxmcData_new(item, isBlob = false) {
      item.year = new Date().getFullYear()
      item.major_name = item.major_name || item.xibu_name
      let num = 1
      item.students.map(s => {
        s.yx_ly = ''
        s.yx_wd = '未定'
        s.yx_wu = ''

        if (window.pmid == 226 && item.class_name && (item.class_name.indexOf('护理') > -1 || item.class_name.indexOf('助产') > -1)) {
          s.department = '护理部'
          s.job = '护理'
          s.yx_wd = '未定'
        }
        if (window.pmid == 226 && item.class_name && item.class_name.indexOf('康复') > -1) {
          s.department = '康复科'
          s.job = '康复'
        }
        if (window.pmid == 226 && item.class_name && item.class_name.indexOf('老年') > -1) {
          s.department = '养老护理部'
          s.job = '护理员'
        }
        if (window.pmid == 226 && item.class_name && (item.class_name.indexOf('医电') > -1 || item.class_name.indexOf('影像') > -1 || item.class_name.indexOf('放射') > -1)) {
          s.department = '影像科'
          s.job = '技士'
        }
        s.age = 0
        if (s.birthday) {
          s.age = this.getCurrentAgeByBirthDate(s.birthday)
        }
        s.id = num
        s.zzmm = s.zzmm == '团员' ? "是" : "否"
        s.gender = s.gender == 1 ? "男" : '女'
        s.phone = s.phone ?? ""
        s.remarks = s.remarks ?? ''
        s.jtzz = s.jtzz ?? ''
        num++
      })
      if (window.pmid == 210 || window.pmid == 214) {
        let excelData = [
          [`${item.year}届毕业生顶岗实习名册`],
          ['填表说明：1.学校名称、实习单位名称请完整填写。\n' +
            '2.专业名称请按照专业目录规范填写。\n' +
            '3.实习起止日期请按实际填写。\n' +
            '4.录取意向：留用、未定、不留用'],
          [`学校：${item.school_name}                  专业：${item.major_name}                班级：${item.class_name}                班主任：${item.teacher_name}         联系方式：${item.phone}`],
          ['序号', '姓名', '性别', '年龄', '实习单位', '实习部门', '实习岗位', '实习时间', '', '录取意向', '联系方式'],
          ['', '', '', '', '', '', '', '起', '止', '', '']
        ]
        item.students.forEach(stu => {
          excelData.push([
            stu.id,
            stu.stu_name,
            stu.gender,
            stu.age,
            stu.firm_name,
            stu.department,
            stu.job,
            stu.start_time,
            stu.end_time,
            stu.start_time ? '留用' : '未定',
            stu.phone,
          ])
        })
        if (isBlob) {
          return {
            blob: njxx_sxmc_blob(excelData),
            filename: `${item.class_name ? item.class_name + '-' : ''}毕业生顶岗实习名册.xlsx`
          }
        }
        return excelData
      }

      if (isBlob) {
        return {
          blob: exportWordBlob('./docs/毕业班实习生名册模板.docx', item),
          filename: `${item.class_name ? item.class_name + '-' : ''}毕业班实习生名册.docx`
        }
      }
      return item
    },
    exportReport() {
      let loadingInstance = this.$loading({
        lock: true,
        text: '正在处理中，请稍等片刻....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      })
      this.$http.post('/api/export_sx_report',{
        class_id: this.deptId,
        dept_type: this.deptType,
        jc: this.searchForm.jc,
        report_month: this.searchForm.report_month,
      }).then(res => {
        var timer = setTimeout(() => {
          this.$message.error('下载失败，请重试！')
          clearInterval(this.sxbgTimer)
          loadingInstance.close()
        },60000)
        this.sxbgTimer = setInterval(() => {
          this.$http.post('/api/delayedDownloadFile',{
            filename: res.data,
          }).then(r => {
            if (r.data.url) {
              clearTimeout(timer)
              clearInterval(this.sxbgTimer)
              loadingInstance.close()
              this.dialogExportSxbg = false
              window.open(r.data.url,'_blank')
            }
          })
        },5000)
      }).catch(err => {
        loadingInstance.close()
      })
    },
    sxglTableExport() {
      this.$http.post('/api/sxglTableExportToExcel', {
        class_ids: this.deptId,
        dept_type: this.deptType,
      }).then(res =>{
        if (res.data.url) {
          if (this.deptType == 'bj') {
            this.$http.get(res.data.url, {
              responseType: 'arraybuffer'
            }).then(r => {
              var blob = new Blob([r.data], { type: 'application/octet-stream;charset=UTF-8' })
              var downloadElement = document.createElement('a')
              var href = window.URL.createObjectURL(blob) // 创建下载的链接
              downloadElement.style.display = 'none'
              downloadElement.href = href
              downloadElement.download = decodeURIComponent(res.data.filename) // 下载后文件名
              document.body.appendChild(downloadElement)
              downloadElement.click() // 点击下载
              document.body.removeChild(downloadElement) // 下载完成移除元素
              window.URL.revokeObjectURL(href)
            })
          } else {
            window.open(res.data.url,'_blank')
          }
        }
      })
    },
  },
  beforeDestroy() {
    clearInterval(this.sxbgTimer)
  }
};
</script>
<style scoped lang="less" type="text/less">
.app-container {
  .treeContainer {
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(220, 223, 230, 0.6);
    margin-right: 20px;
    padding-right: 10px;
    overflow: auto;

    .treeTitle {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #409eff;
      padding-left: 6px;
    }
  }
}

.nodetag {
  display: inline-block;
  padding: 0 5px;
  font-size: 12px;
  transform: scale(0.8, 0.8);
  background-color: #409eff;
  color: #fff;
  border-radius: 4px;
}
</style>
